










































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HelloWorld extends Vue {
  @Prop() private color!: string;
  @Prop() private width!: string;
}
