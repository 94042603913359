import lodash from "lodash";

export const generateString = (count: number) =>
  lodash.times(count, () => lodash.random(35).toString(36)).join("");

export const convertMoney = (value: number): string => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value);
};

export default { generateString, convertMoney };
