
















import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/TheLogo.vue";
import { Dropdown, Icon, Menu, Button } from "ant-design-vue";

@Component({
  components: {
    "a-dropdown": Dropdown,
    "a-icon": Icon,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-button": Button,
    Logo
  }
})
export default class HeaderRight extends Vue {
  logOut() {
    this.$store.dispatch("logout").then(() => {
      this.$router.push({ name: "Login" });
    });
  }
}
