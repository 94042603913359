





























import { Component, Vue } from "vue-property-decorator";
import Start from "@/components/TheStart.vue";
import TextIconWidget from "@/components/Widgets/TextIconWidget.vue";
import { Statistics } from "@/types/Statistics";
import { loadDashboardInfo } from "@/api/dashboard";
import { convertMoney } from "@/common/helper";

@Component({
  components: {
    Start,
    TextIconWidget
  }
})
export default class Home extends Vue {
  loading = false;
  statistics: Statistics = {};

  convertMoney = convertMoney;

  mounted() {
    this.loadLicenses();
  }

  async loadLicenses() {
    this.loading = true;
    const { data } = await loadDashboardInfo();
    this.statistics = data;
    this.loading = false;
  }
}
