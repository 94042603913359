import Vue from "vue";
import Antd from "ant-design-vue";
import lodash from "lodash";
import VueLodash from "vue-lodash";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/vendors/style";
import "./assets/styles/wieldy.less";
import ApiService from "./api/api.service";
import { getToken } from "./common/jwt.service";
import "./vee-validate";

require("@/assets/scss/main.scss");

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(VueLodash, { name: "custom", lodash });

ApiService.init();

router.beforeEach((to, from, next) => {
  if (
    to.path !== "/login" &&
    to.path !== "/register" &&
    to.path !== "/new-password" &&
    to.path !== "/recovery"
  ) {
    const token = getToken();
    if (!token || token === "undefined") {
      next("login");
    } else {
      store.dispatch("auth/me");
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
