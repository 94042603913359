import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth.module";
import lang from "./lang.module";
import database from "./database.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    lang,
    database
  },
  plugins: [createPersistedState()]
});
