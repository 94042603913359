import {
  required,
  confirmed,
  length,
  email,
} from 'vee-validate/dist/rules';
import {
  extend
} from 'vee-validate';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match',
});

extend('length', {
  ...length,
  message: 'This field must have 2 options',
});

extend('min', {
  validate(value, {
    min
  }) {
    return Number(value) >= min;
  },
  params: ['min'],
  message: 'This field must be greater than {min}',
});

extend('minmax', {
  validate(value, {
    min,
    max
  }) {
    return Number(value) >= min && Number(value) <= max;
  },
  params: ['min', 'max'],
  message: 'This field must be greater than {min} and less than {max}',
});
