import { LangState } from "@/types/LangState";
import { RootState } from "@/types/RootState";
import { ActionContext } from "vuex";

type LangContext = ActionContext<LangState, RootState>;

const state: LangState = {
  lang: "en"
};

const actions = {
  updateLang(context: LangContext, lang: string) {
    context.commit("setLang", lang);
  }
};

const mutations = {
  setLang(state: LangState, lang: string) {
    state.lang = lang;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
