import { AuthState } from "@/types/AuthState";
import { RootState } from "@/types/RootState";
import { ActionContext } from "vuex";
import { destroyToken, getToken, saveToken } from "@/common/jwt.service";
import ApiService from "@/api/api.service";
import { User } from "@/types/User";

type AuthContext = ActionContext<AuthState, RootState>;

const state: AuthState = {
  errors: {},
  user: {
    email: "",
    token: "",
    credits: 0
  },
  isAuthenticated: !!getToken(),
  active: false,
  version: "0.0.0",
  update: false
};

const getters = {
  currentUser(state: AuthState) {
    return state.user;
  },
  isAuthenticated(state: AuthState) {
    return state.isAuthenticated;
  }
};

const actions = {
  login(context: AuthContext, credentials: object) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/admin-auth/login", credentials)
        .then(({ data }) => {
          if (data.error) {
            context.commit("setError", data.error);
            reject(data.error);
          }
          // eslint-disable-next-line no-param-reassign
          data.user.token = data.accessToken;
          ApiService.setHeader();
          document.cookie = `user=${data.user.email}; domain=.proip.info`;
          context.commit("setAuth", data.user);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  logout(context: AuthContext) {
    document.cookie = "user=none; domain=.proip.info";
    context.commit("purgeAuth");
  },
  // eslint-disable-next-line consistent-return
  me(context: AuthContext) {
    if (getToken()) {
      return new Promise(resolve => {
        ApiService.setHeader();
        ApiService.get("api/admin-auth/me")
          .then(({ data }) => {
            if (typeof data.user !== "undefined") {
              // eslint-disable-next-line no-param-reassign
              data.user.token = data.accessToken;
              context.commit("setAuth", data.user);
              context.commit("setVersion", data.version);
            } else {
              context.commit("purgeAuth");
            }
            resolve();
          })
          .catch(({ response }) => {
            if (typeof response !== "undefined") {
              context.commit("setError", response.code);
              context.commit("purgeAuth");
            }
          });
      });
    }
    context.commit("purgeAuth");
  }
};

const mutations = {
  setError(state: AuthState, error: object) {
    state.errors = error;
  },
  setAuth(state: AuthState, user: User) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    if (state.user.token) {
      saveToken(state.user.token);
    }
  },
  setVersion(state: AuthState, version: string) {
    if (state.version !== version) {
      if (state.version !== "0.0.0") {
        state.update = true;
      }
      state.version = version;
    } else {
      state.update = false;
    }
  },
  purgeAuth(state: AuthState) {
    state.isAuthenticated = false;
    delete state.user;
    state.errors = {};
    destroyToken();
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
