

























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import HeaderRight from "@/components/TheHeaderRight.vue";
import Menu from "@/components/TheMenu.vue";

@Component({
  components: {
    HeaderRight,
    Menu
  }
})
export default class HelloWorld extends Vue {
  withOutSideBar = ["Login", "Register"];

  collapsedSideBar = false;
  mobileMenu = true;

  collapsed() {
    this.collapsedSideBar = !this.collapsedSideBar;
  }

  mobileCollapsed() {
    this.mobileMenu = !this.mobileMenu;
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    if (this.mobileMenu) {
      this.mobileCollapsed();
    }
  }
}
