

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextIconWidget extends Vue {
  @Prop() private icon!: string;
  @Prop() private description!: string;
  @Prop() private data!: number;
}
