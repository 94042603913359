


























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class TheMenu extends Vue {
  @Prop() collapse!: boolean;
  rootSubmenuKeys: string[] = ["sub1"];
  openKeys: string[] = [];
  adminMenu: string[] = [
    "Users",
    "PricingPlans",
    "Promos",
    "PlansAdmin",
    "InvoicesAdmin",
    "ReferralsAdmin"
  ];

  selectedKeys: string[] = [];

  mounted() {
    if (typeof this.$route.name !== "undefined" && this.$route.name) {
      this.selectedKeys = [this.$route.name];
    }
  }

  onOpenChange(openKeys: [string]) {
    if (typeof this.$route.name !== "undefined" && this.$route.name) {
      this.selectedKeys = [this.$route.name];
    }
    const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
    if (
      typeof latestOpenKey !== "undefined" &&
      this.rootSubmenuKeys.indexOf(latestOpenKey) === -1
    ) {
      this.openKeys = openKeys;
    } else {
      this.openKeys = latestOpenKey ? [latestOpenKey] : [];
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    if (typeof newVal.name !== "undefined" && newVal.name) {
      this.selectedKeys = [newVal.name];
      if (this.adminMenu.indexOf(newVal.name) !== -1) {
        this.openKeys = ["sub1"];
      }
    }
  }
}
