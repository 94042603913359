





























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Start extends Vue {
  toggleBodyClass = (addRemoveClass: string, className: string) => {
    const el = document.body;

    if (addRemoveClass === "addClass") {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  };

  mounted() {
    this.toggleBodyClass("addClass", "start-body");
  }

  destroyed() {
    this.toggleBodyClass("removeClass", "start-body");
  }
}
