import Vue from "vue";
import axios, { AxiosInstance } from "axios";
import applyCaseMiddleware from "axios-case-converter";
import VueAxios from "vue-axios";
import router from "@/router";
import { API_URL } from "../common/config";
import { getToken } from "../common/jwt.service";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    if (getToken() !== "") {
      this.setHeader();
    }
  },

  setHeader() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
  },

  getClient(): AxiosInstance {
    return applyCaseMiddleware(Vue.axios.create());
  },

  query(resource: string, params: object) {
    const res = this.getClient()
      .get(`${resource}`, { params })
      .catch(error => Promise.reject(error.response));
    this.checkRes(res);
    return res;
  },

  get(resource: string) {
    const res = this.getClient()
      .get(`${resource}`)
      .catch(error => Promise.reject(error.response));
    this.checkRes(res);
    return res;
  },

  post(resource: string, params: object) {
    const res = this.getClient()
      .post(`${resource}`, params)
      .catch(error => Promise.reject(error.response));
    this.checkRes(res);
    return res;
  },

  put(resource: string, params: object) {
    const res = this.getClient()
      .put(`${resource}`, params)
      .catch(error => Promise.reject(error.response));
    this.checkRes(res);
    return res;
  },

  delete(resource: string) {
    return this.getClient()
      .delete(resource)
      .catch(error => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkRes(res: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    res.catch((resT: any) => {
      if (resT.status === 401 || resT.status === 403) {
        router.push({ name: "Login" });
      }
    });
  }
};

export default ApiService;
