import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Admin panel ProIP.info"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      title: "Sign in - Admin panel ProIP.info"
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      title: "Profile - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () => import(/* webpackChunkName: "admin-users" */ "../views/Admin/Users.vue"),
    meta: {
      title: "Users - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/user/:id",
    name: "UserEdit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "admin-user-edit" */ "../views/Admin/UserPage.vue"),
    meta: {
      title: "Users edit - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/databases",
    name: "Databases",
    component: () =>
      import(/* webpackChunkName: "admin-databases" */ "../views/Admin/Database.vue"),
    meta: {
      title: "Databases - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/database/create",
    name: "DatabaseCreate",
    props: true,
    component: () =>
      import(/* webpackChunkName: "admin-database-edit" */ "../views/Admin/DatabaseForm.vue"),
    meta: {
      title: "Database create - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/database/:id",
    name: "DatabaseEdit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "admin-database-edit" */ "../views/Admin/DatabaseForm.vue"),
    meta: {
      title: "Database edit - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/promos",
    name: "Promos",
    component: () => import(/* webpackChunkName: "admin-promos" */ "../views/Admin/Promos.vue"),
    meta: {
      title: "Promos - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/promo/create",
    name: "PromoCreate",
    props: true,
    component: () =>
      import(/* webpackChunkName: "admin-promo-edit" */ "../views/Admin/PromoForm.vue"),
    meta: {
      title: "Promo create - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/promo/:id",
    name: "PromoEdit",
    props: true,
    component: () =>
      import(/* webpackChunkName: "admin-promo-edit" */ "../views/Admin/PromoForm.vue"),
    meta: {
      title: "Promo edit - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/invoices",
    name: "InvoicesAdmin",
    component: () => import(/* webpackChunkName: "admin-invoices" */ "../views/Admin/Invoices.vue"),
    meta: {
      title: "Invoices - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/referrals",
    name: "ReferralsAdmin",
    component: () =>
      import(/* webpackChunkName: "admin-referrals" */ "../views/Admin/Referrals.vue"),
    meta: {
      title: "Referrals - Admin panel ProIP.info"
    }
  },
  {
    path: "/admin/licenses",
    name: "LicensesAdmin",
    component: () => import(/* webpackChunkName: "admin-licenses" */ "../views/Admin/Licenses.vue"),
    meta: {
      title: "Licenses - Admin panel ProIP.info"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
